.header {
  display: grid;

  .location {
    font-size: 3.2rem;
    font-weight: 600;
  }

  .changeLocation {
    margin-top: 2rem;
  }
}

@media screen and (min-width: 768px) {
  .header {
    grid-auto-flow: column;

    .changeLocation {
      margin-top: 0;
      justify-self: end;
    }
  }
}
