.currentTemperature {
  display: flex;
  flex: 1 0 0;
  margin-top: 1.6rem;
  width: 100%;

  .iconContainer {
    display: flex;
    flex-grow: 1.25;
    justify-content: center;
    align-items: center;

    .icon {
      width: 168px;
    }
  }

  .contentContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-grow: 1;
    text-align: center;

    .value {
      font-size: 8.4rem;
      font-weight: 300;
    }

    .summary {
      margin-top: -1.4rem;
      margin-left: -1.8rem;
      text-align: center;
      font-size: 2.2rem;
    }
  }
}

@media screen and (min-width: 700px) {
  .currentTemperature {
    width: 50%;

    .contentContainer {
      .summary {
        margin-top: 0;
      }
    }
  }
}
