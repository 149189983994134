.autoCompleteContainer {
  position: relative;
}

.autoCompleteInput {
  border: 1px solid transparent;
  background: rgba(230, 230, 230, 0.85);
  outline: none;
  border-radius: 4rem;
  padding: 0.5rem 1rem;
  width: 100%;
  font-size: 1.8rem;

  &:focus {
    background: rgba(255, 255, 255, 0.85);
  }
}

.loadingIcon {
  position: absolute;
  right: 15px;
  top: 4px;
  bottom: 4px;
  width: 24px;
  height: 24px;
}

.autoCompleteList {
  position: absolute;
  left: 2rem;
  right: 2rem;
  margin-top: 1rem;
  max-height: 50vh;
  overflow-y: auto;
  padding: 0;
  background: #017;
  list-style: none;
  max-width: 600px;
}

li {
  font-size: 2rem;
  padding: 0.75rem 1.5rem;
  border-bottom: 1px solid #80a3b8;

  &:hover {
    background: #039;
    cursor: pointer;
  }
}
