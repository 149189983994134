.noWeatherData {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: grid;
  place-items: center center;
  font-size: 1.8rem;
}

@media (min-width: 600px) {
  .noWeatherData {
    font-size: 2.2rem;
  }
}
