.layoutContainer {
  display: grid;
  justify-items: center;
  padding: 20px;
  min-height: 100vh;
  background: linear-gradient(
    to bottom,
    rgba(43, 50, 178, 0.9) 0%,
    rgba(20, 136, 204, 0.9) 100%
  );
  transition: background-color 600ms;

  &.darkMode {
    background-image: linear-gradient(
      to bottom,
      rgba(5, 5, 20, 0.95) 0%,
      rgba(1, 6, 10, 0.95) 100%
    );
  }

  .innerContainer {
    width: 100%;
    max-width: 880px;
  }
}

@media screen and (min-width: 920px) {
  .innerContainer {
    font-size: 1.76rem;
  }
}
