.item {
  display: flex;
  flex-direction: column;
  padding: 1.4rem 0;
  flex: 1 0 0;
  border-radius: 5px;
  background-color: rgba(0, 0, 0, 0.15);
  font-size: 1.8rem;
  align-items: center;

  .timePeriodIcon {
    width: 45px;
    height: 45px;
  }

  &.darkMode {
    background-color: rgba(40, 40, 50, 0.45);
  }
}

.hour {
  margin-bottom: 1rem;
}
