.autoCompleteList {
  position: absolute;
  left: 2rem;
  right: 2rem;
  margin-top: 1rem;
  max-height: 50vh;
  overflow-y: auto;
  padding: 0;
  background: #017;
  list-style: none;
  max-width: 600px;
}

li {
  font-size: 2rem;
  padding: 0.75rem 1.5rem;
  border-bottom: 1px solid #80a3b8;

  &:hover {
    background: #039;
    cursor: pointer;
  }
}
