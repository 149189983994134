.layoutContainer {
  display: grid;
  justify-items: center;
  padding: 20px;
  min-height: 100vh;
  background: linear-gradient(
    to bottom,
    rgb(43, 50, 178) 0%,
    rgb(20, 136, 204) 100%
  );

  .innerContainer {
    width: 100%;
    max-width: 880px;
  }
}

@media screen and (min-width: 920px) {
  .innerContainer {
    font-size: 1.76rem;
  }
}
