.weatherFailedContainer {
  display: grid;
  justify-content: center;
  align-content: center;
  text-align: center;
  grid-gap: 1.5rem;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;

  h1 {
    font-size: 3.2rem;
    font-weight: 600;
  }
}
