#locationSection {
  display: grid;
  justify-content: center;
  text-align: center;
  margin-top: 15vh;

  h1 {
    font-size: 3.2rem;
    margin-bottom: 2rem;
  }

  p {
    font-size: 1.8rem;
    margin-bottom: 1.6rem;
  }
}