.row {
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 1fr;
  grid-template-rows: repeat(2, 1fr);
  grid-row-gap: 1vh;
  justify-items: center;
  align-items: center;
  margin-bottom: 1.1rem;
  padding: 2.2rem 0;
  border-radius: 5px;
  background-color: rgba(0, 0, 0, 0.2);
  font-size: 2.4rem;
  text-align: center;

  .label {
    color: rgba(255, 255, 255, 0.6);
    font-size: 0.8em;
  }

  .fieldValue {
    font-size: 1.8rem;
  }

  .icon {
    display: grid;
    place-items: center center;
    width: 45px;
    height: 45px;
  }

  &.darkMode {
    background-color: rgba(40, 40, 50, 0.45);
  }
}

@media screen and (min-width: 450px) {
  .row {
    grid-template-rows: 1fr;
  }
}
