.currentStats {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 3.5rem;
  padding: 2rem;
  justify-items: center;
  margin-top: 1.6rem;
  width: 100%;
  border-top: 1px solid rgba(255, 255, 255, 0.5);
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);

  .value {
    font-size: 2.5rem;
  }

  .label {
    color: rgba(255, 255, 255, 0.6);
  }
}

@media screen and (min-width: 700px) {
  .currentStats {
    width: 50%;
    border-top: none;
    border-bottom: none;
    border-left: 1px solid rgba(255, 255, 255, 0.5);
  }
}
